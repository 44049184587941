<div class="dialog-content" mat-dialog-content>
  <h3 mat-dialog-title class="dialog-title">
    {{ contentText.title | translate }}
  </h3>
  <p class="dialog-message">
    <span [innerHTML]="contentText.message | translate"></span>
  </p>
</div>
<div *appLet="selectRunningJobs$ | async as jobs" class="jobs">
  @if (jobs.length) {
    <div>
      @for (job of jobs; track job) {
        <ix-job-item [job]="job"></ix-job-item>
      }
    </div>
  }
</div>
