<mat-sidenav-container class="app-side-nav-container">
  <!-- Main side navigation -->
  <mat-sidenav
    class="sidebar-panel not-alerts"
    [style.flex-basis]="sidenavWidth"
    [opened]="isSidenavOpen"
    [mode]="sidenavMode"
    [disableClose]="sidenavMode !== 'over'"
    (closedStart)="onMenuClosed()"
  >
    <div id="scroll-area" class="navigation-hold">
      <div [ngClass]="['branding', 'topbar', 'truenas-' + (productType$ | async | lowercase)]">
        <a routerLink="/dashboard" class="logo" ixTest="main-logo">
          <ix-icon
            class="app-logo"
            [name]="isDefaultTheme ? 'ix:logo_truenas_scale_mark_color': 'ix:logo_truenas_scale_mark'"
          ></ix-icon>
        </a>
        <a routerLink="/dashboard" class="logo-text" ixTest="main-logo">
          <ix-icon
            class="app-logo-text"
            [name]="isDefaultTheme ? 'ix:logo_truenas_scale_type_color': 'ix:logo_truenas_scale_type'"
          ></ix-icon>
        </a>
      </div>

      <ix-navigation
        [isSidenavCollapsed]="isSidenavCollapsed"
        (menuToggled)="toggleMenu($event)"
        (menuClosed)="toggleMenu()"
      ></ix-navigation>

      <div class="slidein-nav slidein-nav-sm" [class.slidein-open]="isOpenSecondaryMenu">
        <ix-secondary-menu
          [subMenuItems]="subs"
          [menuName]="menuName"
          (toggleMenu)="toggleMenu()"
        ></ix-secondary-menu>
      </div>

      @if (isSidenavCollapsed) {
        <div
          class="sidenav-copyright-icon"
          matTooltipPosition="right"
          [matTooltip]="'TrueNAS ' + (productType$ | async | mapValue: productTypeLabels) + '® © ' + copyrightYear() + ' by iXsystems, Inc.'"
        >
          <ix-icon name="copyright"></ix-icon>
        </div>
      } @else {
        <div class="sidenav-copyright-txt">
          @if (hostname$ | async; as hostname) {
            <div
              class="hostname-label"
              [matTooltip]="'Hostname: {hostname}' | translate: { hostname }"
            >{{ hostname }}</div>
          }
          <ix-copyright-line class="copyright-line"></ix-copyright-line>
        </div>
      }
    </div>
  </mat-sidenav>

  <mat-sidenav-content
    class="fn-maincontent"
    [style.flex-basis]="'calc(100% - ' + sidenavWidth + ')'"
    [style.margin-left]="sidenavWidth"
  >
    <ix-topbar></ix-topbar>

    <div class="slidein-nav slidein-nav-md" [class.slidein-open]="isOpenSecondaryMenu">
      <ix-secondary-menu
        [subMenuItems]="subs"
        [menuName]="menuName"
        (toggleMenu)="toggleMenu()"
      ></ix-secondary-menu>
    </div>
    <div class="overlay" [class.isdark]="isOpenSecondaryMenu" (click)="toggleMenu()"></div>

    <!-- App content -->
    <main
      class="rightside-content-hold"
      [class.has-console-footer]="hasConsoleFooter$ | async"
    >
      <ix-default-page-header></ix-default-page-header>
      <router-outlet></router-outlet>
    </main>
    @if (hasConsoleFooter$ | async) {
      <ix-console-footer></ix-console-footer>
    }
  </mat-sidenav-content>

  <!-- Alert bar -->
  <mat-sidenav
    class="alert-sidenav"
    position="end"
    [opened]="isAlertPanelOpen$ | async"
    (closed)="onAlertsPanelClosed()"
  >
    <ix-alerts-panel></ix-alerts-panel>
  </mat-sidenav>
</mat-sidenav-container>

<ix-slide-in id="ix-slide-in-form"></ix-slide-in>
<ix-chained-slide-in id="ix-chained-slide-in-form"></ix-chained-slide-in>
