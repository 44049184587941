<ix-icon
  class="alert-icon"
  [name]="icon"
  [color]="alertLevelColor"
  [matTooltip]="iconTooltip"
></ix-icon>

<div class="alert-body">
  @if (!alert().dismissed) {
    <h3 [class]="['alert-level', alertLevelColor]">
      {{ levelLabel() }}
    </h3>
  }
  <h4 class="alert-message" [innerHTML]="alert().formatted"></h4>
  @if (isHaLicensed()) {
    <div class="alert-node">{{ alert().node }}</div>
  }
  <div class="alert-time">
    {{ alert().datetime.$date | formatDateTime }}
    @if (timezone$ | async; as timezone) {
      ({{ timezone }})
    }
  </div>

  @if (alert().dismissed) {
    <a
      tabindex="0"
      role="button"
      class="action-link"
      [ixTest]="[alert().key, 'reopen']"
      (click)="onReopen()"
    >
      {{ 'Re-Open' | translate }}
    </a>
  } @else {
    <a
      tabindex="0"
      role="button"
      class="action-link"
      [ixTest]="[alert().key, 'dismiss']"
      (click)="onDismiss()"
    >
      {{ 'Dismiss' | translate }}
    </a>
  }
</div>
