<form [formGroup]="form" (submit)="onSubmit()">
  <div>
    {{ 'Set password for TrueNAS administrative user:' | translate }}
  </div>

  <ix-input
    type="text"
    formControlName="username"
    autocomplete="username"
    [label]="'Username' | translate"
    [readonly]="true"
  ></ix-input>

  <ix-input
    type="password"
    formControlName="password"
    autocomplete="new-password"
    [label]="'Password' | translate"
    [required]="true"
  ></ix-input>

  <ix-input
    type="password"
    formControlName="password2"
    autocomplete="new-password"
    [label]="'Reenter Password' | translate"
    [required]="true"
  ></ix-input>

  <button
    mat-button
    color="primary"
    type="submit"
    class="submit-button"
    ixTest="sign-in"
    [disabled]="!form.valid || (isLoading$ | async)"
  >{{ "Sign In" | translate }}</button>
</form>
