@if (!isLoading || results?.length) {
  <mat-card class="search-results-card">
    @for (section of availableSections; track trackBySection($index, section)) {
      <h2 class="section">
        {{ section.label | translate }}
      </h2>
      @if (getElementsBySection(section.value)?.length) {
        <div class="section-content">
          @if (getLimitedSectionResults(section.value); as sectionResults) {
            @for (result of sectionResults; track trackById(i, result); let i = $index) {
              <div
                class="search-result"
                role="link"
                [attr.tabindex]="result.section === GlobalSearchSection.RecentSearches ? i + 1 : 0"
                [class.highlighted-result]="isSearchInputFocused && isSameHierarchyResult(firstAvailableSearchResult, result)"
                [ixTest]="['search-result', result.hierarchy.join('-')]"
                [attr.aria-label]="'UI Search Result: {result}' | translate: { result: result.hierarchy.join(' ') }"
                (click)="selectElement(result)"
                (keydown.enter)="selectElement(result)"
              >
                <h3 class="title" [innerHTML]="processHierarchy(result.hierarchy, searchTerm)"></h3>

                @if (result.section === GlobalSearchSection.RecentSearches) {
                  <ix-icon
                    role="button"
                    name="close"
                    class="icon"
                    color="accent"
                    [attr.tabindex]="sectionResults.length + i + 1"
                    [attr.aria-label]="'Remove {value} from recent searches' | translate:{ value: result.hierarchy[result.hierarchy.length - 1] }"
                    (click)="removeRecentSearch($event, result)"
                    (keydown.enter)="removeRecentSearch($event, result)"
                  ></ix-icon>
                }
              </div>
            }

            @if (getElementsBySection(section.value)?.length > initialResultsLimit) {
              <button
                class="toggle-show-more"
                mat-button
                ixTest="toggle-show-all-results"
                [attr.tabindex]="sectionResults[0].section === GlobalSearchSection.RecentSearches ? sectionResults.length * 2 + 1 : 0"
                (click)="toggleShowAll(section.value)"
                (keydown.enter)="toggleShowAll(section.value)"
              >
                {{ showAll[section.value] ? ('View Less' | translate) : ('View More' | translate) }}
              </button>
            }
          }
        </div>
      }
      @if (!getElementsBySection(section.value)?.length) {
        <h4 class="no-results">{{ 'No results found in {section}' | translate:{ section: section.label } }}</h4>
      }
    }
  </mat-card>
}
