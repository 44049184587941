<div mat-dialog-title>{{ 'TrueCommand Cloud Service' | translate }}</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="tc-dialog-content">
  <div id="status">
    @if (tc.status === TrueCommandStatus.Connected) {
      <ix-icon
        class="status-connected"
        name="check_circle"
      ></ix-icon>
    }
    @if (tc.status === TrueCommandStatus.Failed) {
      <ix-icon
        class="status-failed"
        name="cancel"
      ></ix-icon>
    }
    @if (tc.status === TrueCommandStatus.Disabled) {
      <ix-icon
        class="status-disabled"
        name="pause_circle_filled"
      ></ix-icon>
    }
    <span>{{ tc.status | translate }}</span>
  </div>
  @if (tc.status_reason) {
    <div id="error">
      {{ tc.status_reason | translate }}
    </div>
  }
  @if (tc.status === TrueCommandStatus.Connected && tc.remote_ip_address) {
    <div id="tc-ip">
      <ix-icon name="ix:logo_truecommand_blue" class="tc-icon"></ix-icon>
      <span>{{ tc.remote_ip_address }}</span>
    </div>
  }
</div>
<div mat-dialog-actions>
  <button
    mat-button
    color="primary"
    class="tc-action-button"
    ixTest="truecommand-settings"
    (click)="data.parent.handleUpdate()"
  >
    {{ 'Settings' | translate }}
  </button>
  <button
    mat-button
    color="primary"
    class="tc-action-button"
    ixTest="go-to-truecommand"
    [disabled]="!tc.remote_url || tc.status !== TrueCommandStatus.Connected"
    (click)="goToTrueCommand()"
  >
    {{ 'TrueCommand' }}
  </button>
</div>
