@if (label || tooltip) {
  <ix-label
    [label]="label"
    [tooltip]="tooltip"
    [required]="required"
    [ixTestOverride]="controlDirective.name"
  ></ix-label>
}

<mat-button-toggle-group
  [value]="value"
  [disabled]="isDisabled"
  [ixTest]="controlDirective.name"
  [vertical]="vertical"
  [attr.aria-label]="label"
  (change)="onValueChanged($event)"
>
  @for (option of options | async; track option.label) {
    <mat-button-toggle
      [name]="option.value.toString()"
      [disabled]="isDisabled"
      [value]="option.value"
      [ixTest]="[controlDirective.name, option.label]"
      [attr.aria-label]="option.label"
    >{{ option.label | translate }}</mat-button-toggle>
  }
</mat-button-toggle-group>

<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>
@if (hint) {
  <mat-hint>{{ hint }}</mat-hint>
}
