<p class="failover-status-message">{{ statusMessage() | translate }}</p>
@if (areReasonsShown()) {
  <div class="failover-reasons">
    @for (reason of disabledReasons(); track reason) {
      <p>
        {{ reason | mapValue: disabledReasonLabels | translate }}
      </p>
    }
  </div>
}

<p class="failover-status">{{ statusDescriptions[status()] }}</p>
@if (!failoverIps()) {
  <ngx-skeleton-loader></ngx-skeleton-loader>
}
@if (failoverIps()?.length) {
  <div class="failover-ips">
    <p>{{ 'Active IP Addresses' | translate }}: {{ failoverIps().join(", ") }}</p>
  </div>
}
