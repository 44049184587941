@if (label || tooltip) {
  <ix-label
    [label]="label"
    [tooltip]="tooltip"
    [required]="required"
  ></ix-label>
}

<div class="star-rating" [attr.aria-label]="label">
  @for (rating of ratings; track index; let index = $index) {
    <div>
      <button
        mat-icon-button
        type="button"
        [disabled]="isDisabled"
        [ixTest]="[controlDirective.name, index + 1]"
        [attr.aria-label]="'Click to give {index} star rating.' | translate: { index: index + 1 }"
        (click)="onValueChanged(index + 1)"
        (blur)="onTouch()"
      >
        <ix-icon [name]="value >= index + 1 ? 'mdi-star' : 'mdi-star-outline'"></ix-icon>
      </button>
    </div>
  }
</div>

<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>
