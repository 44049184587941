<a
  href="https://www.ixsystems.com"
  target="_blank"
  ixTest="ixsystems"
>
  <ix-icon
    [class]="['ix-logo', logoIcon$ | async]"
    [class.xs]="isXsScreen$ | async"
    [name]="logoIcon$ | async"
  ></ix-icon>
</a>
