<div class="panel-header">
  <h3>{{ 'HA Status' | translate }}</h3>
</div>

<div class="panel-content">
  @if (failoverDisabledReasons.length) {
    <div class="status-line">
      <ix-icon name="warning"></ix-icon>
      {{ 'HA is disabled' | translate }}
    </div>
  } @else {
    <div class="status-line status-line--enabled">
      <ix-icon name="check_circle"></ix-icon>
      {{ 'HA is enabled' | translate }}
    </div>
  }

  @if (failoverDisabledReasons.length) {
    <ul class="disabled-reasons">
      @for (reason of failoverDisabledReasons; track reason) {
        <li>
          {{ reason | mapValue: disabledReasonExplanations | translate }}
        </li>
      }
    </ul>
  }
</div>
