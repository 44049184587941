[
  {
    "hierarchy": [
      "Toolbar",
      "Jobs History"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "jobs-history",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Power Menu"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "power-menu",
    "triggerAnchor": "power-menu",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Power Menu",
      "Restart"
    ],
    "synonyms": [
      "Reboot"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "restart",
    "triggerAnchor": "power-menu",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Power Menu",
      "Shut Down"
    ],
    "synonyms": [
      "Power Off",
      "Turn Off"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "shut-down",
    "triggerAnchor": "power-menu",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Send Feedback"
    ],
    "synonyms": [
      "Bug",
      "Feature Request",
      "Suggestion",
      "Improvement",
      "Review",
      "Report Bug",
      "Jira",
      "Ticket",
      "Issue",
      "Defect",
      "File Ticket"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "send-feedback",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Alerts"
    ],
    "synonyms": [
      "Notifications"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "alerts",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Settings Menu"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "user-menu",
    "triggerAnchor": "user-menu",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Settings Menu",
      "Change Password"
    ],
    "synonyms": [
      "Update Password",
      "Set new password",
      "Reset password",
      "New password",
      "User password"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "change-password",
    "triggerAnchor": "user-menu",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Settings Menu",
      "Guide"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "guide",
    "triggerAnchor": "user-menu",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Settings Menu",
      "About"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "about",
    "triggerAnchor": "user-menu",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Settings Menu",
      "Log Out"
    ],
    "synonyms": [
      "Sign Out",
      "Logout",
      "Logoff",
      "End session",
      "Exit",
      "Drop session"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "log-out",
    "triggerAnchor": "user-menu",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Toolbar",
      "Status of TrueCommand"
    ],
    "synonyms": [
      "TrueCommand"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "routerLink": null,
    "anchor": "tc-status",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Groups",
      "Show Built-in Groups"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "groups"
    ],
    "routerLink": null,
    "anchor": "show-built-in-groups",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Groups",
      "Add Group"
    ],
    "synonyms": [
      "New Group",
      "Create Group",
      "Group",
      "Add Local Group"
    ],
    "requiredRoles": [
      "ACCOUNT_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "groups"
    ],
    "routerLink": null,
    "anchor": "add-group",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Groups"
    ],
    "synonyms": [
      "Local Groups"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "groups"
    ],
    "routerLink": null,
    "anchor": "credentials-groups",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Groups",
      "Privileges",
      "Add Privilege"
    ],
    "synonyms": [
      "New Privilege",
      "Create Privilege",
      "Privilege"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "groups",
      "privileges"
    ],
    "routerLink": null,
    "anchor": "add-privilege",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Groups",
      "Privileges"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "groups",
      "privileges"
    ],
    "routerLink": null,
    "anchor": "credentials-groups-privileges",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Users",
      "Show Built-in Users"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "users"
    ],
    "routerLink": null,
    "anchor": "show-built-in-users",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Users",
      "Add User"
    ],
    "synonyms": [
      "New User",
      "Create User",
      "User",
      "Add Local User"
    ],
    "requiredRoles": [
      "ACCOUNT_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "users"
    ],
    "routerLink": null,
    "anchor": "add-user",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Users"
    ],
    "synonyms": [
      "Local Users",
      "User List",
      "User Management",
      "Admins",
      "Administrators"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "users"
    ],
    "routerLink": null,
    "anchor": "credentials-users",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "API Keys"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/apikeys"
    ],
    "routerLink": null,
    "anchor": "api-keys",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Applications",
      "Discover"
    ],
    "synonyms": [
      "Apps"
    ],
    "requiredRoles": [],
    "visibleTokens": [
      "APPS"
    ],
    "anchorRouterLink": [
      "/apps",
      "available"
    ],
    "routerLink": null,
    "anchor": "applications-discover",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Applications",
      "Discover",
      "Custom App"
    ],
    "synonyms": [],
    "requiredRoles": [
      "APPS_WRITE"
    ],
    "visibleTokens": [
      "APPS"
    ],
    "anchorRouterLink": [
      "/apps",
      "available"
    ],
    "routerLink": null,
    "anchor": "custom-app",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Applications",
      "Installed",
      "Settings"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [
      "APPS"
    ],
    "anchorRouterLink": [
      "/apps",
      "installed",
      "*"
    ],
    "routerLink": null,
    "anchor": "app-settings",
    "triggerAnchor": "app-settings",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Applications",
      "Installed",
      "Settings",
      "Choose Pool"
    ],
    "synonyms": [],
    "requiredRoles": [
      "DOCKER_WRITE"
    ],
    "visibleTokens": [
      "APPS"
    ],
    "anchorRouterLink": [
      "/apps",
      "installed",
      "*"
    ],
    "routerLink": null,
    "anchor": "choose-pool",
    "triggerAnchor": "app-settings",
    "section": "ui"
  },
  {
    "hierarchy": [
      "Applications",
      "Installed"
    ],
    "synonyms": [
      "Apps"
    ],
    "requiredRoles": [],
    "visibleTokens": [
      "APPS"
    ],
    "anchorRouterLink": [
      "/apps",
      "installed"
    ],
    "routerLink": null,
    "anchor": "applications-installed",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Audit"
    ],
    "synonyms": [
      "Logs"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "audit"
    ],
    "routerLink": null,
    "anchor": "system-audit",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Backup Credentials"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "backup-credentials"
    ],
    "routerLink": null,
    "anchor": "credentials-backup-credentials",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Cloud Credentials"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "backup-credentials"
    ],
    "routerLink": null,
    "anchor": "cloud-credentials",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Cloud Credentials",
      "Add Cloud Credential"
    ],
    "synonyms": [
      "Add Credential",
      "Add Backup Credential",
      "New Credential",
      "New Could Credential",
      "New Backup Credential",
      "Create Credential",
      "Create Cloud Credential",
      "Create Backup Credential",
      "Credential",
      "Cloud Credential",
      "Backup Credential"
    ],
    "requiredRoles": [
      "CLOUD_SYNC_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "backup-credentials"
    ],
    "routerLink": null,
    "anchor": "add-backup-credential",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "SSH Connections"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "backup-credentials"
    ],
    "routerLink": null,
    "anchor": "ssh-connections",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "SSH Connections",
      "Add SSH Connection"
    ],
    "synonyms": [
      "New SSH Connection",
      "Create SSH Connection",
      "SSH Connection"
    ],
    "requiredRoles": [
      "KEYCHAIN_CREDENTIAL_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "backup-credentials"
    ],
    "routerLink": null,
    "anchor": "add-ssh-connection",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "SSH Keypairs"
    ],
    "synonyms": [
      "Keypairs",
      "Public Key",
      "Private Key",
      "SSH Key"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "backup-credentials"
    ],
    "routerLink": null,
    "anchor": "ssh-keypairs",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "SSH Keypairs",
      "Add SSH Keypair"
    ],
    "synonyms": [
      "New SSH Keypair",
      "Create SSH Keypair",
      "SSH Keypair",
      "Add Key",
      "New Key",
      "Create Key"
    ],
    "requiredRoles": [
      "KEYCHAIN_CREDENTIAL_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "backup-credentials"
    ],
    "routerLink": null,
    "anchor": "add-ssh-keypair",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Certificates",
      "ACME DNS-Authenticators"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "certificates"
    ],
    "routerLink": null,
    "anchor": "acme-dns-authenticator-list",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Certificates",
      "ACME DNS-Authenticators",
      "Add ACME DNS-Authenticator"
    ],
    "synonyms": [
      "New ACME DNS-Authenticator",
      "Create ACME DNS-Authenticator",
      "ACME DNS-Authenticator",
      "Add DNS Authenticator",
      "New DNS Authenticator",
      "Create DNS Authenticator"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "certificates"
    ],
    "routerLink": null,
    "anchor": "add-acme-dns-authenticator",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Certificates",
      "Certificate Authorities"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "certificates"
    ],
    "routerLink": null,
    "anchor": "certificate-authority-list",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Certificates",
      "Certificate Authorities",
      "Add Certificate Authority"
    ],
    "synonyms": [
      "New Certificate Authority",
      "Create Certificate Authority",
      "Certificate Authority"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "certificates"
    ],
    "routerLink": null,
    "anchor": "add-certificate-authority",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Certificates",
      "Add Certificate"
    ],
    "synonyms": [
      "Create Certificate",
      "New Certificate",
      "Generate Certificate"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "certificates"
    ],
    "routerLink": null,
    "anchor": "add-certificate",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Certificates"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "certificates"
    ],
    "routerLink": null,
    "anchor": "credentials-certificates",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Certificates",
      "Certificate Signing Requests"
    ],
    "synonyms": [
      "CSRs"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "certificates"
    ],
    "routerLink": null,
    "anchor": "csr-list",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Certificates",
      "Certificate Signing Requests",
      "Add CSR"
    ],
    "synonyms": [
      "Create CSR",
      "New CSR",
      "Generate CSR",
      "Create Certificate Signing Requests",
      "New Certificate Signing Requests",
      "Add Certificate Signing Requests",
      "Generate Certificate Signing Requests"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "certificates"
    ],
    "routerLink": null,
    "anchor": "add-csr",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "KMIP"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "kmip"
    ],
    "routerLink": null,
    "anchor": "credentials-kmip",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Dashboard",
      "Configure Dashboard"
    ],
    "synonyms": [
      "Update Dashboard",
      "Home Widgets",
      "Widgets",
      "New Widget",
      "Add Widget"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/dashboard"
    ],
    "routerLink": null,
    "anchor": "configure-dashboard",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Dashboard"
    ],
    "synonyms": [
      "Widgets"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/dashboard"
    ],
    "routerLink": null,
    "anchor": "dashboard",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "TrueCloud Backup Tasks",
      "Add TrueCloud Backup Task"
    ],
    "synonyms": [
      "Add Cloud Backup",
      "Create TrueCloud Backup Task",
      "Create Cloud Backup",
      "New TrueCloud Backup Task",
      "New Cloud Backup",
      "Task"
    ],
    "requiredRoles": [
      "CLOUD_BACKUP_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "cloud-backup"
    ],
    "routerLink": null,
    "anchor": "add-cloud-backup",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "TrueCloud Backup Tasks"
    ],
    "synonyms": [
      "Tasks",
      "Cloud Backup"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "cloud-backup"
    ],
    "routerLink": null,
    "anchor": "data-protection-truecloud-backup-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Cloud Sync Tasks",
      "Add Cloud Sync Task"
    ],
    "synonyms": [
      "Create Cloud Sync Task",
      "New Cloud Sync Task",
      "Task"
    ],
    "requiredRoles": [
      "CLOUD_SYNC_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "cloudsync"
    ],
    "routerLink": null,
    "anchor": "add-cloudsync",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Cloud Sync Tasks"
    ],
    "synonyms": [
      "Tasks",
      "Storj iX",
      "Amazon S3",
      "Backblaze B2",
      "Box",
      "Dropbox",
      "FTP",
      "Google Cloud Storage",
      "Google Drive",
      "Google Photos",
      "Hubic",
      "HTTP",
      "Mega",
      "Microsoft Azure",
      "OpenStack Swift",
      "pCloud",
      "SFTP",
      "Storj",
      "WebDAV",
      "Yandex"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "cloudsync"
    ],
    "routerLink": null,
    "anchor": "data-protection-cloud-sync-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection"
    ],
    "synonyms": [
      "Tasks"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection"
    ],
    "routerLink": null,
    "anchor": "data-protection",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Replication Tasks",
      "Add Replication Task"
    ],
    "synonyms": [
      "Create Replication Task",
      "New Replication Task",
      "Task"
    ],
    "requiredRoles": [
      "REPLICATION_TASK_WRITE",
      "REPLICATION_TASK_WRITE_PULL"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "replication"
    ],
    "routerLink": null,
    "anchor": "add-replication",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Replication Tasks"
    ],
    "synonyms": [
      "Tasks"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "replication"
    ],
    "routerLink": null,
    "anchor": "data-protection-replication-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Rsync Tasks",
      "Add Rsync Task"
    ],
    "synonyms": [
      "Create Rsync Task",
      "New Rsync Task",
      "Task"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "rsync"
    ],
    "routerLink": null,
    "anchor": "add-rsync",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Rsync Tasks"
    ],
    "synonyms": [
      "Tasks"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "rsync"
    ],
    "routerLink": null,
    "anchor": "data-protection-rsync-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Scrub Tasks",
      "Resilver Priority"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "scrub",
      "priority"
    ],
    "routerLink": null,
    "anchor": "data-protection-scrub-tasks-resilver-priority",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Scrub Tasks",
      "Add Scrub Task"
    ],
    "synonyms": [
      "Create Scrub Task",
      "New Scrub Task",
      "Task"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "scrub"
    ],
    "routerLink": null,
    "anchor": "add-scrub",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Scrub Tasks"
    ],
    "synonyms": [
      "Tasks"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "scrub"
    ],
    "routerLink": null,
    "anchor": "data-protection-scrub-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Scrub Tasks",
      "Adjust Scrub/Resilver Priority"
    ],
    "synonyms": [
      "Adjust Scrub Priority",
      "Adjust Resilver Priority"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection"
    ],
    "routerLink": [
      "/data-protection",
      "scrub",
      "priority"
    ],
    "anchor": "adjust-scrub/resilver-priority",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Periodic S.M.A.R.T. Tests",
      "Add S.M.A.R.T. Test"
    ],
    "synonyms": [
      "Add Periodic S.M.A.R.T. Test",
      "Add Smart Test",
      "Create Periodic S.M.A.R.T. Test",
      "Create Smart Test",
      "New Periodic S.M.A.R.T. Test",
      "New Smart Test",
      "Test",
      "Smart",
      "Add Disk Test",
      "New Disk Test",
      "Create Disk Test"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "smart"
    ],
    "routerLink": null,
    "anchor": "add-smart-test",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Periodic S.M.A.R.T. Tests"
    ],
    "synonyms": [
      "Tasks",
      "Smart",
      "S.M.A.R.T.",
      "Smart Tests",
      "Disk Tests"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "smart"
    ],
    "routerLink": null,
    "anchor": "data-protection-periodic-s.m.a.r.t.-tests",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Periodic Snapshot Tasks",
      "VMware Snapshot Integration"
    ],
    "synonyms": [
      "Integrate Snapshots with VMware"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection"
    ],
    "routerLink": [
      "/data-protection",
      "vmware-snapshots"
    ],
    "anchor": "vmware-snapshot-integration",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Periodic Snapshot Tasks",
      "Add Snapshot Task"
    ],
    "synonyms": [
      "Add Periodic Snapshot Task",
      "Create Periodic Snapshot Task",
      "New Periodic Snapshot Task",
      "Create Snapshot Task",
      "New Snapshot Task",
      "Snapshot",
      "Task"
    ],
    "requiredRoles": [
      "SNAPSHOT_TASK_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "snapshot"
    ],
    "routerLink": null,
    "anchor": "add-snapshot-task",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "Periodic Snapshot Tasks"
    ],
    "synonyms": [
      "Tasks"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "snapshot"
    ],
    "routerLink": null,
    "anchor": "data-protection-periodic-snapshot-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Data Protection",
      "VMware Snapshots"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/data-protection",
      "vmware-snapshots"
    ],
    "routerLink": null,
    "anchor": "data-protection-vmware-snapshots",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Create Snapshot"
    ],
    "synonyms": [
      "Manage Datasets",
      "Take Snapshot"
    ],
    "requiredRoles": [
      "SNAPSHOT_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "create-snapshot",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Manage Snapshots"
    ],
    "synonyms": [
      "Manage Datasets",
      "Snapshot Manager"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": [
      "/datasets/snapshots",
      "dataset().id"
    ],
    "anchor": "manage-snapshots",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Manage Snapshots Tasks"
    ],
    "synonyms": [
      "Manage Datasets",
      "Snapshot Task Manager"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": [
      "/data-protection/snapshot",
      "dataset().id"
    ],
    "anchor": "manage-snapshots-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Manage Replication Tasks"
    ],
    "synonyms": [
      "Manage Datasets",
      "Replication Task Manager"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": [
      "/data-protection/replication",
      "dataset().id"
    ],
    "anchor": "manage-replication-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Manage Rsync Tasks"
    ],
    "synonyms": [
      "Manage Datasets",
      "Rsync Task Manager"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": [
      "/data-protection/rsync",
      "dataset().id"
    ],
    "anchor": "manage-rsync-tasks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Manage User Quotas"
    ],
    "synonyms": [
      "Manage Datasets",
      "User Quota Manager"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": [
      "/",
      "datasets",
      "dataset.id",
      "user-quotas"
    ],
    "anchor": "manage-user-quotas",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Manage Group Quotas"
    ],
    "synonyms": [
      "Manage Datasets",
      "Group Quota Manager"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": [
      "/",
      "datasets",
      "dataset.id",
      "group-quotas"
    ],
    "anchor": "manage-group-quotas",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Add Zvol"
    ],
    "synonyms": [
      "Manage Datasets",
      "Add Volume",
      "Create Zvol",
      "New Zvol"
    ],
    "requiredRoles": [
      "DATASET_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "add-zvol",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Add Dataset"
    ],
    "synonyms": [
      "Manage Datasets",
      "Add Filesystem",
      "Create Dataset",
      "New Dataset"
    ],
    "requiredRoles": [
      "DATASET_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "add-dataset",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Dataset Details"
    ],
    "synonyms": [
      "Manage Datasets",
      "Dataset Information"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "dataset-details",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Dataset Space Management"
    ],
    "synonyms": [
      "Manage Datasets",
      "Dataset Capacity Management"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "dataset-space-management",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "ZFS Encryption"
    ],
    "synonyms": [
      "Manage Datasets",
      "Dataset ZFS Encryption"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "zfs-encryption",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Data Protection"
    ],
    "synonyms": [
      "Manage Datasets",
      "Dataset Data Protection"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "data-protection",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Roles"
    ],
    "synonyms": [
      "Manage Datasets",
      "Dataset Roles"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "roles",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Datasets",
      "Permissions"
    ],
    "synonyms": [
      "Manage Datasets",
      "Dataset Permissions",
      "ACL"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/datasets"
    ],
    "routerLink": null,
    "anchor": "permissions",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Directory Services",
      "Idmap",
      "Add Idmap"
    ],
    "synonyms": [
      "Create Idmap",
      "New Idmap"
    ],
    "requiredRoles": [
      "DIRECTORY_SERVICE_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/directoryservice",
      "idmap"
    ],
    "routerLink": null,
    "anchor": "add-idmap",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Directory Services",
      "Idmap"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/directoryservice",
      "idmap"
    ],
    "routerLink": null,
    "anchor": "directory-services-idmap",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Directory Services",
      "Kerberos Keytabs",
      "Add Kerberos Keytab"
    ],
    "synonyms": [
      "Create Kerberos Keytab",
      "New Kerberos Keytab",
      "Kerberos Keytab"
    ],
    "requiredRoles": [
      "DIRECTORY_SERVICE_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/directoryservice",
      "kerberoskeytabs"
    ],
    "routerLink": null,
    "anchor": "add-kerberos-keytab",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Directory Services",
      "Kerberos Keytabs"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/directoryservice",
      "kerberoskeytabs"
    ],
    "routerLink": null,
    "anchor": "directory-services-kerberos-keytabs",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Directory Services",
      "Kerberos Realms",
      "Add Kerberos Realm"
    ],
    "synonyms": [
      "Create Kerberos Realm",
      "New Kerberos Realm",
      "Kerberos Realm"
    ],
    "requiredRoles": [
      "DIRECTORY_SERVICE_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/directoryservice",
      "kerberosrealms"
    ],
    "routerLink": null,
    "anchor": "add-kerberos-realm",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Directory Services",
      "Kerberos Realms"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/directoryservice",
      "kerberosrealms"
    ],
    "routerLink": null,
    "anchor": "directory-services-kerberos-realms",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Directory Services",
      "Configure Active Directory"
    ],
    "synonyms": [
      "Active Directory"
    ],
    "requiredRoles": [
      "DIRECTORY_SERVICE_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "directory-services"
    ],
    "routerLink": null,
    "anchor": "configure-active-directory",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Directory Services",
      "Configure LDAP"
    ],
    "synonyms": [],
    "requiredRoles": [
      "DIRECTORY_SERVICE_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "directory-services"
    ],
    "routerLink": null,
    "anchor": "configure-ldap",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Directory Services"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "directory-services"
    ],
    "routerLink": null,
    "anchor": "credentials-directory-services",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Jobs"
    ],
    "synonyms": [
      "Jobs History",
      "Completed Jobs",
      "Failed Jobs",
      "Jobs in progress",
      "History"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/jobs"
    ],
    "routerLink": null,
    "anchor": "jobs",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Add Interface"
    ],
    "synonyms": [
      "Networking",
      "NIC",
      "Network Interface Card",
      "New Interface",
      "Create Interface"
    ],
    "requiredRoles": [
      "NETWORK_INTERFACE_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "add-interface",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Interfaces"
    ],
    "synonyms": [
      "Networking",
      "NIC",
      "Network Interface Card"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "interfaces",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Ipmi"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "ipmi",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Show Events"
    ],
    "synonyms": [
      "Show Ipmi Events"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "show-events",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Global Configuration Settings"
    ],
    "synonyms": [
      "Hostname",
      "Domain",
      "HTTP Proxy",
      "Service Announcement",
      "Additional Domains",
      "Hostname Database",
      "Outbound Network",
      "Domain Name System",
      "DNS Servers",
      "DHCP",
      "Gateway",
      "Lan"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "global-configuration-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Global Configuration"
    ],
    "synonyms": [
      "Network Configuration",
      "Network Settings"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "global-configuration",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Nameserver"
    ],
    "synonyms": [
      "NS"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "nameserver",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Static Routes"
    ],
    "synonyms": [
      "Routing",
      "Static Routing"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "static-routes",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network",
      "Static Routes",
      "Add Static Route"
    ],
    "synonyms": [
      "Create Static Route",
      "New Static Route"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "add-static-route",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Network"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/network"
    ],
    "routerLink": null,
    "anchor": "network",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "Reporting Exporters",
      "Add Reporting Exporter"
    ],
    "synonyms": [
      "Add Exporter",
      "New Exporter",
      "Create Exporter",
      "Exporter",
      "New Reporting Exporter",
      "Create Reporting Exporter"
    ],
    "requiredRoles": [
      "REPORTING_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "exporters"
    ],
    "routerLink": null,
    "anchor": "add-reporting-exporter",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "Reporting Exporters"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "exporters"
    ],
    "routerLink": null,
    "anchor": "reporting-reporting-exporters",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "Select Reporting"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard"
    ],
    "routerLink": null,
    "anchor": "select-reporting",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "CPU"
    ],
    "synonyms": [
      "Processor",
      "CPU Stats",
      "CPU Utilization"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "cpu"
    ],
    "routerLink": null,
    "anchor": "cpu",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "Disk"
    ],
    "synonyms": [
      "Device",
      "Disks",
      "Disk IO",
      "Disk I/O",
      "disk stats",
      "disk writes"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "disk"
    ],
    "routerLink": null,
    "anchor": "disk",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "Memory"
    ],
    "synonyms": [
      "RAM",
      "Free RAM",
      "Memory Usage",
      "Memory Stats",
      "Memory Utilization"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "memory"
    ],
    "routerLink": null,
    "anchor": "memory",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "Network"
    ],
    "synonyms": [
      "Network Stats",
      "Network Utilization",
      "Network Usage",
      "Traffic",
      "Network Traffic"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "network"
    ],
    "routerLink": null,
    "anchor": "network",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "System"
    ],
    "synonyms": [
      "System Stats",
      "System Utilization",
      "Uptime"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "system"
    ],
    "routerLink": null,
    "anchor": "system",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "UPS"
    ],
    "synonyms": [
      "UPS Stats",
      "UPS Utilization"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "ups"
    ],
    "routerLink": null,
    "anchor": "ups",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "ZFS"
    ],
    "synonyms": [
      "ZFS Reports",
      "ZFS Stats",
      "ZFS Utilization"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard",
      "zfs"
    ],
    "routerLink": null,
    "anchor": "zfs",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting",
      "Netdata"
    ],
    "synonyms": [
      "View Netdata"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard"
    ],
    "routerLink": null,
    "anchor": "netdata",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Reporting"
    ],
    "synonyms": [
      "Stats"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/reportsdashboard"
    ],
    "routerLink": null,
    "anchor": "reporting",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services",
      "SMB"
    ],
    "synonyms": [
      "Samba"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "service-smb",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services",
      "FTP"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "service-ftp",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services",
      "iSCSI"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "service-iscsi",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services",
      "NFS"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "service-nfs",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services",
      "SNMP"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "service-snmp",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services",
      "SSH"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "service-ssh",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services",
      "UPS"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "service-ups",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services",
      "S.M.A.R.T."
    ],
    "synonyms": [
      "Smart"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "service-smart",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Services"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "services"
    ],
    "routerLink": null,
    "anchor": "system-services",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "Configure iSCSI"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing"
    ],
    "routerLink": [
      "/sharing",
      "iscsi",
      "configuration"
    ],
    "anchor": "configure-iscsi",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "iSCSI Wizard"
    ],
    "synonyms": [
      "Add iSCSI",
      "New iSCSI",
      "Create iSCSI",
      "iSCSI Share",
      "Create Share",
      "Add Share",
      "New Share"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing"
    ],
    "routerLink": null,
    "anchor": "iscsi-wizard",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares"
    ],
    "synonyms": [
      "Sharing",
      "Add Share",
      "New Share",
      "Create Share"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing"
    ],
    "routerLink": null,
    "anchor": "shares",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "Initiators",
      "Add Initiator"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "iscsi",
      "initiators",
      "add"
    ],
    "routerLink": null,
    "anchor": "shares-initiators-add-initiator",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "iSCSI",
      "Target Global Configuration"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "iscsi",
      "configuration"
    ],
    "routerLink": null,
    "anchor": "target-global-configuration",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "iSCSI",
      "Portals"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "iscsi",
      "portals"
    ],
    "routerLink": null,
    "anchor": "portals",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "iSCSI",
      "Initiators Groups"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "iscsi",
      "initiator"
    ],
    "routerLink": null,
    "anchor": "initiators-groups",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "iSCSI",
      "Authorized Access"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "iscsi",
      "auth"
    ],
    "routerLink": null,
    "anchor": "authorized-access",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "iSCSI",
      "Targets"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "iscsi",
      "target"
    ],
    "routerLink": null,
    "anchor": "targets",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "iSCSI",
      "Extents"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "iscsi",
      "extent"
    ],
    "routerLink": null,
    "anchor": "extents",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "iSCSI",
      "Associated Targets"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "iscsi",
      "associatedtarget"
    ],
    "routerLink": null,
    "anchor": "associated-targets",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "NFS",
      "Add NFS Share"
    ],
    "synonyms": [
      "Create NFS Share",
      "New NFS Share",
      "Create Share",
      "Add Share",
      "New Share"
    ],
    "requiredRoles": [
      "SHARING_NFS_WRITE",
      "SHARING_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "nfs"
    ],
    "routerLink": null,
    "anchor": "add-nfs-share",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "NFS"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "nfs"
    ],
    "routerLink": null,
    "anchor": "shares-nfs",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "NFS",
      "NFS Sessions"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "nfs",
      "sessions"
    ],
    "routerLink": null,
    "anchor": "shares-nfs-nfs-sessions",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "SMB",
      "Add SMB Share"
    ],
    "synonyms": [
      "Samba",
      "Create SMB Share",
      "New SMB Share",
      "Create Share",
      "Add Share",
      "New Share"
    ],
    "requiredRoles": [
      "SHARING_SMB_WRITE",
      "SHARING_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "smb"
    ],
    "routerLink": null,
    "anchor": "add-smb-share",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "SMB"
    ],
    "synonyms": [
      "Samba"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "smb"
    ],
    "routerLink": null,
    "anchor": "shares-smb",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "SMB",
      "SMB Status",
      "Sessions"
    ],
    "synonyms": [
      "Samba",
      "SMB Sessions"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "smb",
      "status",
      "sessions"
    ],
    "routerLink": null,
    "anchor": "sessions",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "SMB",
      "SMB Status",
      "Locks"
    ],
    "synonyms": [
      "Samba",
      "SMB Locks"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "smb",
      "status",
      "locks"
    ],
    "routerLink": null,
    "anchor": "locks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "SMB",
      "SMB Status",
      "Shares"
    ],
    "synonyms": [
      "Samba",
      "SMB Shares"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "smb",
      "status",
      "shares"
    ],
    "routerLink": null,
    "anchor": "shares",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Shares",
      "SMB",
      "SMB Status",
      "Notifications"
    ],
    "synonyms": [
      "Samba",
      "SMB Notifications"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/sharing",
      "smb",
      "status",
      "notifications"
    ],
    "routerLink": null,
    "anchor": "notifications",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Shell"
    ],
    "synonyms": [
      "CLI",
      "Terminal",
      "Console",
      "Command Line Interface",
      "Prompt"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "shell"
    ],
    "routerLink": null,
    "anchor": "system-shell",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Export/Disconnect"
    ],
    "synonyms": [
      "Export",
      "Disconnect",
      "Offline"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "export/disconnect",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Expand"
    ],
    "synonyms": [],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "expand",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Disk Health"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "disk-health",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Usage"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "usage",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Topology"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "topology",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Manage Devices"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "manage-devices",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "ZFS Health"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "zfs-health",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Scrub"
    ],
    "synonyms": [],
    "requiredRoles": [
      "REPORTING_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "scrub",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Auto TRIM"
    ],
    "synonyms": [
      "Edit Trim",
      "Edit Auto TRIM"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "auto-trim",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Import Pool"
    ],
    "synonyms": [
      "Add Pool"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "import-pool",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Create Pool"
    ],
    "synonyms": [
      "Add Pool",
      "New Pool",
      "Pool Wizard",
      "Pool Creation Wizard"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": [
      "/storage",
      "create"
    ],
    "anchor": "create-pool",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage"
    ],
    "synonyms": [
      "Pools",
      "Storage Dashboard",
      "Disks",
      "Devices",
      "Datasets"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage"
    ],
    "routerLink": null,
    "anchor": "storage",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Storage",
      "Disks"
    ],
    "synonyms": [
      "Manage Disks"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/storage",
      "disks"
    ],
    "routerLink": null,
    "anchor": "storage-disks",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Access"
    ],
    "synonyms": [
      "Configure Sessions",
      "Sessions"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "access-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Access",
      "Terminate Other Sessions"
    ],
    "synonyms": [
      "Terminate Other User Sessions"
    ],
    "requiredRoles": [
      "AUTH_SESSIONS_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "terminate-other-sessions",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Access",
      "Configure Access"
    ],
    "synonyms": [
      "Access Settings"
    ],
    "requiredRoles": [
      "AUTH_SESSIONS_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "access-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Access",
      "Session Timeout"
    ],
    "synonyms": [
      "Session Token Lifetime"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "session-timeout",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Access",
      "Login Banner"
    ],
    "synonyms": [
      "MOTD"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "login-banner",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings"
    ],
    "synonyms": [
      "Settings"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "system-advanced-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Allowed IP Addressed"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "allowed-addresses-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Configure Allowed IP Addresses"
    ],
    "synonyms": [
      "Allowed IP Addresses Settings"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "allowed-addresses-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Audit"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "audit-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Audit",
      "Configure Audit"
    ],
    "synonyms": [
      "Audit Settings"
    ],
    "requiredRoles": [
      "SYSTEM_AUDIT_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "audit-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Audit",
      "Retention"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "retention",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Audit",
      "Reservation"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "reservation",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Audit",
      "Quota"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "quota",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Audit",
      "Quota Fill Warning"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "quota-fill-warning",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Audit",
      "Quota Fill Critical"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "quota-fill-critical",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Console"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "console-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Console",
      "Configure Console"
    ],
    "synonyms": [
      "Console Settings"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "console-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Console",
      "Console Menu"
    ],
    "synonyms": [
      "Show Text Console without Password Prompt"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "console-menu",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Console",
      "Enable Serial Console"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "enable-serial-console",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Console",
      "Serial Port"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "serial-port",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Console",
      "Serial Speed"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "serial-speed",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Console",
      "MOTD Banner"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "motd-banner",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Cron Jobs",
      "Add Cron Job"
    ],
    "synonyms": [
      "Create Cron Job",
      "New Cron Job",
      "Setup Cron Job",
      "Cron Job",
      "Cronjob"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "add-cron-job",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Cron Jobs"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "cron"
    ],
    "routerLink": null,
    "anchor": "system-cron-jobs",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Global Two Factor Authentication"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "global-two-factor-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Global Two Factor Authentication",
      "Configure Global Two Factor Authentication"
    ],
    "synonyms": [
      "Global Two Factor Authentication Settings",
      "2FA Settings"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "global-two-factor-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Global Two Factor Authentication",
      "Global 2FA Enable"
    ],
    "synonyms": [
      "Enable Two Factor Authentication Globally",
      "Two Factor Auth",
      "2FA"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "global-2fa-enable",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Global Two Factor Authentication",
      "Window"
    ],
    "synonyms": [
      "Tolerance Window"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "window",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Global Two Factor Authentication",
      "SSH"
    ],
    "synonyms": [
      "Enable Two Factor Authentication for SSH"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "ssh",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Init/Shutdown Scripts",
      "Add Init/Shutdown Script"
    ],
    "synonyms": [
      "Create Init/Shutdown Script",
      "New Init/Shutdown Script",
      "Init/Shutdown Script"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "add-init-shutdown-script",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Init/Shutdown Scripts"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "initshutdown"
    ],
    "routerLink": null,
    "anchor": "system-init/shutdown-scripts",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Isolated GPU Device(s)"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "configure-isolated-gpus",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Isolated GPU Device(s)",
      "Configure Isolated GPU Devices"
    ],
    "synonyms": [
      "Isolated GPU Devices Settings"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "isolated-gpus-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Isolated GPU Device(s)",
      "GPUs"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "gpus",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Kernel"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "kernel-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Kernel",
      "Configure Kernel"
    ],
    "synonyms": [
      "Kernel Settings"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "kernel-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Kernel",
      "Enable Kernel Debug"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "enable-kernel-debug",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Replication"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "replication-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Replication",
      "Configure Replication"
    ],
    "synonyms": [
      "Replication Settings"
    ],
    "requiredRoles": [
      "REPLICATION_TASK_CONFIG_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "replication-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Replication",
      "Replication Tasks Limit"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "replication-tasks-limit",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Save Debug"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system/advanced"
    ],
    "routerLink": null,
    "anchor": "save-debug",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Self-Encrypting Drive"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "sed-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Self-Encrypting Drive",
      "Configure Self-Encrypting Drive"
    ],
    "synonyms": [
      "Self-Encrypting Drive Settings"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "sed-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Self-Encrypting Drive",
      "ATA Security User"
    ],
    "synonyms": [
      "SED User"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "ata-security-user",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Self-Encrypting Drive",
      "SED Password"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "sed-password",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Storage"
    ],
    "synonyms": [
      "Disks",
      "Pools"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "storage-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Storage",
      "Configure Storage"
    ],
    "synonyms": [
      "Storage Settings"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "storage-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Storage",
      "System Data Pool"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "system-data-pool",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Sysctl"
    ],
    "synonyms": [
      "Add Tunable",
      "Add Sysctl"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "sysctl",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Sysctl",
      "Add Sysctl"
    ],
    "synonyms": [
      "Add Tunable",
      "Create Sysctl",
      "New Sysctl",
      "Kernel Parameters",
      "Add Kernel Parameters",
      "Create Kernel Parameters",
      "New Kernel Parameters",
      "Create Tunable",
      "New Tunable"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "add-sysctl",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Tunables"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "tunable"
    ],
    "routerLink": null,
    "anchor": "system-tunables",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Syslog"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "syslog-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Syslog",
      "Configure Syslog"
    ],
    "synonyms": [
      "Syslog Settings"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "syslog-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Syslog",
      "Use FQDN for Logging"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "use-fqdn-for-logging",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Syslog",
      "Syslog Level"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "syslog-level",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Syslog",
      "Syslog Server"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "syslog-server",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Syslog",
      "Syslog Transport"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "syslog-transport",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Advanced Settings",
      "Syslog",
      "Include Audit Logs"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "advanced"
    ],
    "routerLink": null,
    "anchor": "include-audit-logs",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Alert Settings",
      "Alert Services"
    ],
    "synonyms": [
      "Alerts",
      "Configure Notifications",
      "Configure Alerts"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "alert-settings",
      "services"
    ],
    "routerLink": null,
    "anchor": "system-alert-settings-alert-services",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Alert Settings",
      "Alert Services",
      "Add Alert"
    ],
    "synonyms": [
      "Alerts",
      "Configure Notifications",
      "Configure Alerts",
      "Create Alert",
      "New Alert",
      "Notifications"
    ],
    "requiredRoles": [
      "ALERT_LIST_WRITE"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "alert-settings",
      "services"
    ],
    "routerLink": null,
    "anchor": "add-alert-service",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Boot Environments",
      "Stats/Settings"
    ],
    "synonyms": [
      "Create boot environment"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "boot"
    ],
    "routerLink": null,
    "anchor": "stats/settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Boot Environments",
      "Scrub Boot Pool"
    ],
    "synonyms": [
      "Create boot environment"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "boot"
    ],
    "routerLink": null,
    "anchor": "scrub-boot-pool",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Boot Environments"
    ],
    "synonyms": [
      "Create boot environment"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "boot"
    ],
    "routerLink": null,
    "anchor": "system-boot-environments",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Boot",
      "Boot Pool Status"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "boot",
      "status"
    ],
    "routerLink": null,
    "anchor": "system-boot-boot-pool-status",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "View Enclosure",
      "NVMe-oF Expansion Shelves"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [
      "ENCLOSURE"
    ],
    "anchorRouterLink": [
      "/system",
      "viewenclosure",
      "jbof"
    ],
    "routerLink": null,
    "anchor": "system-view-enclosure-nvme-of-expansion-shelves",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Failover"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [
      "FAILOVER"
    ],
    "anchorRouterLink": [
      "/system",
      "failover"
    ],
    "routerLink": null,
    "anchor": "system-failover",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Failover",
      "Sync To Peer"
    ],
    "synonyms": [],
    "requiredRoles": [
      "FAILOVER_WRITE"
    ],
    "visibleTokens": [
      "FAILOVER"
    ],
    "anchorRouterLink": [
      "/system",
      "failover"
    ],
    "routerLink": null,
    "anchor": "sync-to-peer",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Failover",
      "Sync From Peer"
    ],
    "synonyms": [],
    "requiredRoles": [
      "FAILOVER_WRITE"
    ],
    "visibleTokens": [
      "FAILOVER"
    ],
    "anchorRouterLink": [
      "/system",
      "failover"
    ],
    "routerLink": null,
    "anchor": "sync-from-peer",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Email"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "email-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Email",
      "Configure Email"
    ],
    "synonyms": [
      "Send Test Email",
      "Config Email",
      "Set email"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "email-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Email",
      "Send Method"
    ],
    "synonyms": [
      "SMTP",
      "Gmail"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "send-method",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings"
    ],
    "synonyms": [
      "Settings"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "system-general-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "gui-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "GUI Settings"
    ],
    "synonyms": [],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "gui-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "Theme"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "theme",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "SSL Certificate"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "ssl-certificate",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "IPv4 Address"
    ],
    "synonyms": [
      "Web Interface Address"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "ipv4-address",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "IPv6 Address"
    ],
    "synonyms": [
      "Web Interface Address"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "ipv6-address",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "HTTP Port"
    ],
    "synonyms": [
      "Web Interface Port"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "http-port",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "HTTPS Port"
    ],
    "synonyms": [
      "Web Interface Port",
      "SSL Web Interface Port"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "https-port",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "SSL Protocols"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "ssl-protocols",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "HTTPS Redirect"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "https-redirect",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "Usage Collection"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "usage-collection",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "GUI",
      "Show Console Messages"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "show-console-messages",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Localization"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "localization-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Localization",
      "Localization Settings"
    ],
    "synonyms": [],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "localization-settings",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Localization",
      "Language"
    ],
    "synonyms": [
      "Translate App"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "language",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Localization",
      "Date Format"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "date-format",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Localization",
      "Time Format"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "time-format",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Localization",
      "Timezone"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "timezone",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Localization",
      "Console Keyboard Map"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "console-keyboard-map",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Manage Configuration"
    ],
    "synonyms": [],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "manage-configuration",
    "triggerAnchor": "manage-configuration",
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Manage Configuration",
      "Download File"
    ],
    "synonyms": [
      "Export File",
      "Export Configuration",
      "Download Configuration",
      "Export Config",
      "Save Config",
      "Download Config",
      "Backup Config"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "download-file",
    "triggerAnchor": "manage-configuration",
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Manage Configuration",
      "Upload File"
    ],
    "synonyms": [
      "Import File",
      "Import Configuration",
      "Upload Configuration",
      "Import Config",
      "Upload Config",
      "Restore Config"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "upload-file",
    "triggerAnchor": "manage-configuration",
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "Manage Configuration",
      "Reset to Defaults"
    ],
    "synonyms": [
      "Restore Defaults",
      "Reset Defaults",
      "Restore Default Config",
      "Reset Default Config",
      "Reset Config",
      "Restore Config Defaults",
      "Restore Default Configuration"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "reset-to-defaults",
    "triggerAnchor": "manage-configuration",
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "NTP Server"
    ],
    "synonyms": [
      "Add NTP Server"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "ntp-card",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "General Settings",
      "NTP Server",
      "Add NTP Server"
    ],
    "synonyms": [
      "Create NTP Server",
      "New NTP Server",
      "Time Server"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "general"
    ],
    "routerLink": null,
    "anchor": "add-ntp-server",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Support",
      "Eula"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "support",
      "eula"
    ],
    "routerLink": null,
    "anchor": "system-support-eula",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Support"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "support"
    ],
    "routerLink": null,
    "anchor": "system-support",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Support",
      "License"
    ],
    "synonyms": [
      "Update License",
      "Add License",
      "License Update"
    ],
    "requiredRoles": [
      "FULL_ADMIN"
    ],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "support"
    ],
    "routerLink": null,
    "anchor": "license",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Support",
      "File Ticket"
    ],
    "synonyms": [
      "Rate this page"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "support"
    ],
    "routerLink": null,
    "anchor": "file-ticket",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Update",
      "Manual Update"
    ],
    "synonyms": [
      "Install Manual Update File",
      "Manual Upgrade",
      "Upload Manual Update File"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "update",
      "manualupdate"
    ],
    "routerLink": null,
    "anchor": "system-update-manual-update",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Update",
      "Check for Updates"
    ],
    "synonyms": [
      "Upgrade",
      "Update System",
      "Update Software",
      "Check for Software Updates",
      "Download Updates"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "update"
    ],
    "routerLink": null,
    "anchor": "check-for-updates",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "System",
      "Update",
      "Release Notes"
    ],
    "synonyms": [
      "Check Release Notes",
      "View Release Notes",
      "View Changelog",
      "Changelog",
      "Upgrade Release Notes",
      "Update Release Notes"
    ],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/system",
      "update"
    ],
    "routerLink": null,
    "anchor": "release-notes",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Two-Factor Authentication"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "two-factor"
    ],
    "routerLink": null,
    "anchor": "credentials-two-factor-authentication",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Credentials",
      "Two-Factor Authentication",
      "Configure 2FA Secret"
    ],
    "synonyms": [],
    "requiredRoles": [],
    "visibleTokens": [],
    "anchorRouterLink": [
      "/credentials",
      "two-factor"
    ],
    "routerLink": null,
    "anchor": "configure-2fa-secret",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Virtual Machines",
      "Add VM"
    ],
    "synonyms": [
      "Create VM",
      "New VM",
      "VM",
      "Add Virtual Machine",
      "New Virtual Machine",
      "Create Virtual Machine"
    ],
    "requiredRoles": [
      "VM_WRITE"
    ],
    "visibleTokens": [
      "VMS"
    ],
    "anchorRouterLink": [
      "/vm"
    ],
    "routerLink": null,
    "anchor": "add-vm",
    "triggerAnchor": null,
    "section": "ui"
  },
  {
    "hierarchy": [
      "Virtual Machines"
    ],
    "synonyms": [
      "VM",
      "Virtualization"
    ],
    "requiredRoles": [],
    "visibleTokens": [
      "VMS"
    ],
    "anchorRouterLink": [
      "/vm"
    ],
    "routerLink": null,
    "anchor": "virtual-machines",
    "triggerAnchor": null,
    "section": "ui"
  }
]