<mat-nav-list
  role="navigation"
  [attr.aria-label]="'Main menu' | translate"
>
  @for (item of menuItems; track item; let i = $index) {
    <div>
      @if (!item.isVisible$ || (item.isVisible$ | async)) {
        <!-- links and submenus -->
        <mat-list-item
          class="sidebar-list-item sidenav-link"
          role="listitem"
          routerLinkActive="highlighted"
          [id]="'nav-' + i"
          [class.has-submenu]="item.type === MenuItemType.SlideOut"
          [class.highlighted]="isHighlighted === item.state"
          (click)="updateHighlightedClass(item.state)"
        >
          @if (item.type === MenuItemType.Link) {
            <a
              matTooltipPosition="right"
              [ixTest]="getItemName(item)"
              [routerLink]="getRouterLink(item.state)"
              [name]="getItemName(item)"
              [matTooltip]="isSidenavCollapsed() ? (item.tooltip | translate) : null"
              (click)="closeMenu()"
            >
              <ix-icon [name]="item.icon"></ix-icon>
              <span>{{ item.name | translate }}</span>
            </a>
          } @else if (item.type === MenuItemType.SlideOut) {
            <a
              tabindex="0"
              matTooltipPosition="right"
              [ixTest]="getItemName(item)"
              [name]="getItemName(item)"
              [matTooltip]="isSidenavCollapsed() ? (item.tooltip | translate) : null"
              (click)="toggleMenu(item.state, item.sub)"
              (keyup.enter)="toggleMenu(item.state, item.sub)"
              >
              <ix-icon [name]="item.icon"></ix-icon>
              <span>{{ item.name | translate }}</span>
              <ix-icon class="menu-caret">keyboard_arrow_right</ix-icon>
            </a>
          }
        </mat-list-item>
      }
    </div>
  }
</mat-nav-list>
