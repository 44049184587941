<mat-dialog-content class="dialog">
  <div class="header">
    <h3>{{ 'Directory Services' | translate }}</h3>

    <div class="header-actions">
      <!-- TODO: Use subscription instead. Move logic to a common component store/service -->
      <button
        mat-icon-button
        ixTest="refresh-directory-services"
        [attr.aria-label]="'Refresh' | translate"
        (click)="getStatus()"
      >
        <ix-icon name="refresh"></ix-icon>
      </button>

      <button
        mat-icon-button
        mat-dialog-close
        ixTest="close-directory-services"
        [aria-label]="'Close' | translate"
      >
        <ix-icon name="clear"></ix-icon>
      </button>
    </div>
  </div>
  @if (isLoading()) {
    <div class="spinner-wrapper">
      <mat-spinner id="dir-service-monitor-spinner" [diameter]="40"></mat-spinner>
    </div>
  } @else {
    <a
      class="status-row"
      [ixTest]="'go-to-directory-services'"
      [routerLink]="['/credentials', 'directory-services']"
    >
      <div>{{ serviceName() }}</div>
      <div class="state">
        @switch (state()) {
          @case (DirectoryServiceState.Healthy) {
            <ix-icon name="check_circle" class="icon state-healthy"></ix-icon>
          }
          @case (DirectoryServiceState.Faulted) {
            <ix-icon name="highlight_off" class="icon state-faulted"></ix-icon>
          }
          @case (DirectoryServiceState.Leaving) {
            <ix-icon name="arrow_back" class="icon state-leaving"></ix-icon>
          }
          @case (DirectoryServiceState.Joining) {
            <ix-icon name="arrow_forward" class="icon state-joining"></ix-icon>
          }
          @case (DirectoryServiceState.Disabled) {
            <ix-icon name="remove_circle" class="icon state-disabled"></ix-icon>
          }
        }

        {{ state() | mapValue: directoryServiceStateLabels | translate }}
      </div>
    </a>
  }
</mat-dialog-content>
